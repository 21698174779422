<template>
  <div>

    <div class="jumbotron jumbotron-fluid title__secondary">
      <div class="container max-width-1000px">
        <h1 class="display-4 title-center">Phishing Awareness Training<br><small>Maak je medewerkers weerbaarder tegen
          phishingaanvallen</small></h1>
      </div>
    </div>

    <div class="bg--white">
      <div class="container">

        <div class="space--50"></div>

        <div class="row justify-content-center">
          <div class="col-md-10">
            <ul class="lead">
              <li>75% van de geslaagde cyberaanvallen begint met phishing.</li>
              <li>Een geslaagde cyberaanval kost een grote organisatie gemiddeld € 2,5 miljoen.</li>
              <li>De Phishing Awareness Training van Checkjelinkje maakt je medewerkers weerbaarder tegen phishingaanvallen.</li>
            </ul>
            <p>
              We hoeven je waarschijnlijk niet te vertellen dat mensen vaak de <strong>zwakste schakel</strong> vormen als het gaat om de
              <strong>digitale beveiliging</strong> van een organisatie.
            </p>
            <p>
              Dat treft: je bent hier op de goede plek. <strong>Checkjelinkje</strong> is in korte tijd een autoriteit op het gebied
              van cyberveiligheid geworden en onze plannen voor de toekomst zijn nog veel ambitieuzer.
            </p>
            <p>
              Laat ons je helpen de gegevens van je klanten en je organisatie beter te beveiligen aan de hand
              van een <strong>Phishing Awareness Training</strong> voor je medewerkers. Samen kunnen we cybercriminelen nu stoppen.
            </p>
            <p>
              Een groot voordeel is tegelijkertijd: mensen kun je iets leren. Wat cyberveiligheid aangaat kun je
              daar niet vroeg genoeg mee starten. Maar hoe en waar begin je dan?
            </p>
            <p>
              Deze training is speciaal samengesteld om medewerkers van grote bedrijven en
              organisaties bewust te maken van de gevaren van phishing.
            </p>
            <p>
              <a href="https://l5q0lyunp5v.typeform.com/to/fnCkjOVs" class="btn btn-outline-primary"
                 target="_blank">Ja, ik wil mijn medewerkers weerbaarder maken tegen phishing <i
                  class="far fa-arrow-right"></i></a>
            </p>
            <br>
            <h3 class="text-primary">
              De training
            </h3>
            <p>
              De training is laagdrempelig en ook eenvoudig online te doorlopen. Uw medewerkers hoeven zich niet
              door lange lappen tekst te worstelen, maar leren interactief en vlot aan de hand van een training op locatie, video’s,
              afbeeldingen en korte teksten. Allemaal gemaakt door ons, specialisten op het gebied van online
              veiligheid.
            </p>
            <p>
              Om met bewustwording van het probleem te beginnen, sturen we allereerst een nepmail (die heel
              goed een échte phishingmail zou kunnen zijn). Hoeveel van je medewerkers trappen erin?
              Spoiler: waarschijnlijk meer dan je denkt.
            </p>
            <p>
              Hierna volgt de belangrijkste boodschap: 75% van de geslaagde cyberaanvallen begint met zo’n
              mailtje. En een klik op een potentieel schadelijke link is zo gebeurd… Zoals je misschien zelf wel
              hebt ervaren.
            </p>
            <p>
              Maak je medewerkers <strong>weerbaarder tegen cyberaanvallen</strong> met de Phishing Awareness Training
              van Checkjelinkje. Samen kunnen we cybercriminaliteit een halt toeroepen.
            </p>
            <br>
<!--            <p>-->
<!--              <a href="https://l5q0lyunp5v.typeform.com/to/fnCkjOVs" class="btn btn-outline-primary"-->
<!--                 target="_blank">Ja, ik wil mijn medewerkers leren phishing te herkennen <i-->
<!--                  class="far fa-arrow-right"></i></a>-->
<!--            </p>-->
            <div class="space--20"></div>

            <h3 class="text-primary">
              Waarom Checkjelinkje?
            </h3>
            <p>
              Checkjelinkje is in korte tijd uitgegroeid tot een autoriteit op het terrein van anti-phishing. Met het
              platform checkjelinkje.nl beschermen en waarschuwen we duizenden consumenten door links en
              websites vooraf te beoordelen op (on)betrouwbaarheid.
            </p>
            <p>
              Checkjelinkje wordt onder andere aanbevolen door de Consumentenbond, door Veilig
              Internetten en het ministerie van Veiligheid en Justitie.
            </p>
            <p>
              Bijna driekwart van de Nederlanders heeft weleens te maken gehad met cybercrime. Uit onderzoek blijkt dat in
              2019 13% van alle Nederlanders het slachtoffer is geworden van een vorm van cybercrime.
            </p>
            <div class="space--20"></div>

            <img src="https://imagedelivery.net/jgkWWk-lW6Ge24u2d-w91g/ca72fbfc-4cc1-45c3-f3b7-76f9011e1500/public"
                 alt="" class="img img-fluid">
            <br>
            <br>

          </div>
        </div>
        <div class="space--50"></div>

        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <h3 class="text-primary">De mogelijkheden</h3>
          </div>
          <div class="col-md-12">
            <div class="card-deck mb-3">
              <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <img src="/img/presentation.svg" alt="" height="50">
                  <h4 class="mt-2">Op locatie</h4>
                </div>
                <div class="card-body">
                  <p>
                    Eenmalige training op locatie door de experts van Checkjelinkje. We nemen je medewerkers mee met praktijkvoorbeelden en
                    maken phishing top of mind door een interactieve sessie.
                  </p>
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> In een training van 2 uur maak je phishing awareness weer top of mind bij je medewerkers.</li>
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> Training met veel praktijkvoorbeelden, interactie en oefenmateriaal.</li>
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> Eenmalige kosten.</li>
                  </ul>
                </div>
              </div>
              <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <img src="/img/check-blue.svg" alt="" height="50">
                  <h4>Op locatie en online</h4>
                </div>
                <div class="card-body">
                  <p>
                    De meest complete vorm om met Phishing Awareness aan de slag te gaan. De training begint met een gesimuleerde phishingmail,
                    gevolg door een training op locatie en daarna doorlopend online.
                  </p>
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> Prikkel je medewerkers optimaal om beter met phishing om te gaan, door de combinatie van een gesimuleerde mail, fysieke training en online training.</li>
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> Zorg ervoor dat je medewerkers doorlopend alert blijven op phishing. Ook wanneer de training een paar maanden geleden was.</li>
                  </ul>
                </div>
              </div>
              <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <img src="/img/elearning.svg" alt="" height="50">
                  <h4 class="mt-2">Alleen online</h4>
                </div>
                <div class="card-body">
                  <p>
                    Houd je medewerkers continu alert op phishing, met onze online training. Medewerkers doorlopen met regelmaat onze trainingen zodat zij ook telkens op de hoogte blijven van nieuwe trends.
                  </p>
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> Zorg ervoor dat je medewerkers doorlopend alert blijven op phishing. Ook wanneer de training een paar maanden geleden was.</li>
                    <li><span class="fa-li"><i class="fad fa-check-circle text-primary"></i></span> Vast bedrag per actieve medewerker per maand.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 text-center">
          <div class="col-md-12">
            <p>
              <a href="https://l5q0lyunp5v.typeform.com/to/fnCkjOVs" class="btn btn-lg btn-outline-primary"
                 target="_blank">Ja, ik wil mijn medewerkers weerbaarder maken tegen phishing <i
                  class="far fa-arrow-right"></i></a>
            </p>
          </div>
        </div>
      </div>
      <br><br><br>
    </div>
    <div data-tf-popover="fnCkjOVs" data-tf-button-color="#1856CD"
         data-tf-tooltip="Onze training inzetten in jouw organisatie?" data-tf-chat style="all:unset;"></div>
  </div>
</template>
<style scoped>
#replay {
  text-decoration: none;
}

.card-header {
  background-color: transparent;
  border-bottom: none;
}

.text-primary {
  color: #1856cd!important
}
p, .lead,span, .fa-li, li, h4 {
  color: #587790;
}

h1 > small {
  font-size: 45%;
}

video {
  max-width: 100%;
  height: auto;
}
</style>
<script>
import 'whatwg-fetch';
import detect from 'detect-browser';

export default {
  name: 'PhishingAwarenessTraining',
  data: function() {
    return {};
  },
  methods: {},
  mounted: function() {
    if (document.getElementById('typeform-script')) {
      return;
    }

    var script = document.createElement('script');
    script.id = 'typeform-script';
    script.onload = function() {
      //do stuff with the script
    };
    script.src = 'https://embed.typeform.com/next/embed.js';

    document.head.appendChild(script);
  },
};
</script>
